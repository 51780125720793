import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { client} from '../sanity/sanityClient';
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import styled from "@emotion/styled";
import { allusersroute,contactbymessages, host, updatefcmtoken } from "../utils/APIRoutes";
import { getCurrentToken } from '../firebase';
import ChatContainer from "../components/ChatContainer";
import Contacts from "../components/Contacts";
import ContactsMessaged from "../components/ContactsMessaged";
import Categories from "../components/Categories";
import WholeCategory from "../components/WholeCategory";
import Scraper from "../components/Scraper";
import DisplayUsers from "../components/DisplayUsers";


export default function Chat() {
  const navigate = useNavigate();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [contactList, setContactList]=useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [selectedProduct, setSelectedProduct] = useState(null);
   const [listSet, setListSet]=useState(true);
   const [categoryType, setCategoryType] = useState(true);
   const [showContacts, setShowContacts] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)) {
        navigate("/register");
      } else {
        setCurrentUser(
          await JSON.parse(
            localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
          )
        );
      }
    }

    fetchData();
  }, [navigate]);

  const updateFCMToken = async (userId, token) => {
    try {
      await axios.post(updatefcmtoken, { userId, token }); // Replace with your actual API endpoint
    } catch (error) {
      console.error('Error updating FCM token:', error);
    }
  };

  useEffect(() => {
    // Connect to socket.io and add user on component mount
    if (currentUser) {
      socket.current = io(host);
      socket.current.emit('add-user', currentUser._id);

      // Update FCM token on component mount
      getCurrentToken()
        .then((currentToken) => {
          if (currentToken) {
            updateFCMToken(currentUser._id, currentToken);
          }
        })
        .catch((error) => {
          console.error('Error fetching FCM token:', error);
        });
    }

    return () => {
      // Cleanup socket connection on component unmount
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, [currentUser]);

  useEffect(() => {
    async function fetchContacts() {
      if (currentUser) {
        if (currentUser.isAvatarImageSet) {
          try {
            const data = await axios.get(`${allusersroute}/${currentUser._id}`);
            setContacts(data.data);
          } catch (error) {
            console.error("Error fetching contacts:", error.message);
            // Handle the error here
          }
        } else {
          navigate("/setAvatar");
        }
      }
    }

    fetchContacts();
  }, [currentUser, navigate]);


  useEffect(() => {
    async function fetchContactsList() {
      if (currentUser) {
        if (currentUser.isAvatarImageSet) {
          try {
            const data = await axios.get(`${contactbymessages}/${currentUser._id}`);

            setContactList(data.data);
          } catch (error) {
            console.error("Error fetching contacts:", error.message);
            // Handle the error here
          }
        } else {
          navigate("/setAvatar");
        }
      }
    }

    fetchContactsList();
  }, [currentUser, navigate]);

  const updateContactMessaged=async()=>{
    try {
            const data = await axios.get(`${contactbymessages}/${currentUser._id}`);

            setContactList(data.data);
          } catch (error) {
            console.error("Error fetching contacts:", error.message);
            // Handle the error here
          }

   }

   const changeCategory = () => {
    // Toggle category type
    setCategoryType(!categoryType);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const query = `*[_type in [ "grocery", "beauty", "clothingandfashion", "electronics", "jewelryandaccessories", "fashion"]]`;
        const data = await client.fetch(query);
        const shuffledData=data.sort(()=>Math.random()-0.5);
        setCategories(shuffledData);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleProductDeselect = () => {
    setSelectedProduct(null);
  };

const handleBack=()=>{
    setCurrentChat(undefined);
  }
  

  const showCntcts=()=>{
    setShowContacts(!showContacts);
  }

  return (
    <>
      <Container currentChat={currentChat}>
        <div className="marquee-container">
         <div  className="marquee-container"><div className="marquee-content">Hello <strong>{currentUser? currentUser.username:''}</strong> karibu! Kulipia bidhaa chat na Admin.</div></div>
        </div>
        <div className="toLet" style={{width:"101%", height:"70px"}}>
        <DisplayUsers setListSet={setListSet} contactList={contactList} changeChat={handleChatChange} showCntcts={showCntcts} currentChat={currentChat}/>
        </div>
        <div>
          <div className="container">
            <div className='contacts-container'>{showContacts && (
              listSet ? (
                <ContactsMessaged setListSet={setListSet} contactList={contactList} changeChat={handleChatChange} showCntcts={showCntcts}/>
              ) : (
                <Contacts contacts={contacts} changeChat={handleChatChange} setListSet={setListSet} showCntcts={showCntcts}/>
              )
            )}</div>

            {currentChat === undefined ? (
              categoryType ? (
                <Categories
                  categories={categories}
                  isLoading={isLoading}
                  changeCategory={changeCategory}
                  showCntcts={showCntcts}
                />
              ) : (
                <WholeCategory changeCategory={changeCategory} />
              )
            ) : (
              <ChatContainer
                currentChat={currentChat}
                socket={socket}
                selectedProduct={selectedProduct}
                productDeselect={handleProductDeselect}
                handleBack={handleBack}
                categories={categories}
                fetchMessaged={updateContactMessaged}
              />
            )}
            {currentChat && <Scraper onSelectedProduct={handleProductSelect} categories={categories} isLoading={isLoading} />}
          </div>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  gap: 1rem;
  padding-left:10px;
  align-items: center;
  background-color: white;

  .container {
    position: relative;
    height: calc(100vh - 10vh); /* Adjusted height to leave space for the footer */
    width: 100vw;
    background-color: white;
    display: ${(props) => (props.currentChat ? "grid" : "flex")};
    grid-template-columns: ${(props) => (props.currentChat ? "0% 50% 50%" : "none")};
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: ${(props) => (props.currentChat ? "0% 50% 50%" : "none")};
    }
  }

  .contacts-container {
    position: ${(props) => (props.currentChat ? "relative" : "absolute")};
    z-index: ${(props) => (props.currentChat ? "auto" : "2")};
    top: 0;
    left: 0;
    width: 30%;
  }
`;

