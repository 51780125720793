import React from "react";
import { Link } from 'react-router-dom';
import { GoX } from 'react-icons/go';
import styled from "styled-components";
//import Logo from "../assets/logo.jpg";

export default function ContactsMessaged({showCntcts}) {
  
  
  return (
    <>
     
        <Container>
       
          <div className="contacts">
        <div onClick={showCntcts} style={{position: 'absolute', top:'0px', right:'0'}}><GoX/> </div>
       <LinkContainer>
           <StyledLink to="/items/clothingandfashion" >Clothing</StyledLink>
          <StyledLink to="/items/medical" >Medical</StyledLink>
           <StyledLink to="/items/foodandbeverages" >Food</StyledLink>
           <StyledLink to="/items/kitchen" >Kitchen</StyledLink>
           <StyledLink to="/items/crops" >Crops</StyledLink>
           <StyledLink to="/items/livestock" >Livestock</StyledLink>
           <StyledLink to="/items/estate" >Estate</StyledLink>
           <StyledLink to="/items/books" >Books</StyledLink>
           <StyledLink to="/items/jewelryandaccessories" >Jewelry</StyledLink>
           <StyledLink to="/items/fashion" >Fashion</StyledLink>
           <StyledLink to="/items/grocery" >Grocery</StyledLink>
           <StyledLink to="/items/toys" >Toys</StyledLink>
           <StyledLink to="/items/arts" >Arts</StyledLink>
           <StyledLink to="/items/beauty" >Beauty</StyledLink>
           <StyledLink to="/items/sports" >Sports</StyledLink>
          <StyledLink to="/items/vehicles" >Vehicles</StyledLink>
           <StyledLink to="/items/homeandfurnitures" >Furnitures</StyledLink>
           <StyledLink to="/items/electronics" >Electronics</StyledLink>
           <StyledLink to="/items/hotels" >Hotels</StyledLink>
           <StyledLink to="/items/salon" >Salon</StyledLink>
           <StyledLink to="/items/hardware">Hardware</StyledLink>
          </LinkContainer>

      </div>
            
        </Container>
  
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 75% 15%;
  overflow-y: auto;
  background-color: #f0f0f0;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 2rem;
    }
    h3 {
      color: white;
    }
  }
  
  }
`;


const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 6px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  padding: 8px 12px;
  
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c0c0c0;
  }
`;