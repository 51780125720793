import React from 'react';
import './App.css';

const WholeCategory=({changeCategory})=>{

	return(
        <div className="search-results">
		<h1>Oyoooo!</h1>
		<p>Coming soon.</p>
		<button onClick={changeCategory}>Back to Retail</button>
		</div>
		)
}

export default WholeCategory;