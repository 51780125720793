import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { client } from '../sanity/sanityClient';
import { GoHome} from 'react-icons/go';
import { useStateContext } from '../context/StateContext';
import { AiOutlineShopping } from 'react-icons/ai';
import Cart from './Cart';
import NavBar from './NavBar';
import FetchCategory from './FetchCategory';
import ProductDetail from './ProductDetail';

const Products = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [showCategory, setShowCategory] = useState(true);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false); // Added state
  const { totalQuantities, showCart, setShowCart } = useStateContext();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await client.fetch(
          '*[_type in [ "featured", "beauty", "clothingandfashion", "electronics", "homeandfurnitures", "fashion", "jewelryandaccessories", "medical"] && slug.current == $slug]',
          {
            slug,
          }
        );
        setProducts(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, [slug]);

  const handleFetchCategory = async (category) => {
    setShowCategory(false);
    try {
      const data = await client.fetch(`*[_type == '${category}']`);
      setSubCategories(data);
    } catch (error) {
      console.error(error);
    }
    // Push the new category to the URL
    navigate(`/category/${category}`);
  };

  const toggleMobileDropdown = () => {
    setShowMobileDropdown(!showMobileDropdown);
  };
  const shouldShowComponent = window.innerWidth > 768; 

  return (
    <div className="productsPage" style={{ padding: '20px', textAlign: 'center' }}>
      <div className="NavPan"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          zIndex: '1000',
          top: '10px',
          paddingRight: '20px',
          backgroundColor: '#00000076',
          borderRadius: '10px',
        }}
      >
        <h3 style={{ color: 'white', left: '0' }}>
          <Link to={'/'} style={{color:'white',textDecoration: 'none' }}><GoHome/>Home</Link>
        </h3>
        
     <div style={{display:'flex'}}><h3><Link to="/social" style={{color:'white', textDecoration: 'none'}}>Social</Link></h3></div>
        <button
          type="button"
          className="cart-icon"
          onClick={() => setShowCart(true)}
          style={{ color: 'white', right: '0' }}
        >
          <AiOutlineShopping />
          <span className="cart-item-qty">{totalQuantities}</span>
        </button>
        <div className={`modal ${showCart ? 'active' : ''}`}>
          <Cart />
        </div>
        <div className={`modalTwo ${ showMobileDropdown? 'active' : ''}`}>
          <NavBar handleFetchCategory={handleFetchCategory} toggleMobileDropdown={toggleMobileDropdown}/>
        </div>
      </div>
      <br />
      <div className="productsDisplay">
       {shouldShowComponent && <div className="sideBar">
        </div>}

        {showCategory && <ProductDetail products={products} />}
        {!showCategory && <FetchCategory subCategories={subCategories}/>}
      </div>
    </div>
  );
};

export default Products;
