import React from 'react';
import { Link } from 'react-router-dom';
import { urlFor } from '../sanity/sanityClient';

const SearchForm = ({ searchResults}) => {
  

  return (

      <div className="categories-grid" >
          {searchResults.length>0? (searchResults.map((result) => (
            <div key={result._id} className="result-item">
              <Link to={`/category/${result.slug.current}`} style={{textDecoration:'none'}}>
                <div className="category-content">
                  {result.image[0] && (
                    <img
                      src={urlFor(result.image[0])}
                      width={250}
                      height={250}
                      alt={result.name}
                      style={{ borderRadius: '10px',  background: 'linear-gradient(to right, #ffecd2, #fcb69f)' }}
                    />
                  )}
                  <p style={{whiteSpace:'nowrap'}}>{result.name}</p>
                </div>
              </Link>
            </div>
            )
        )):(<p>No results found!</p>)}
      </div>
   
  );
};

export default SearchForm;
