import React from 'react';

const NavBar = ({handleFetchCategory, toggleMobileDropdown}) => {
  

  return (

      <div className="" style={{backgroundColor: '#00000076'}}>
        
        <div className="product-container">
        <button
          type="button"
          className="modal-close"
          onClick={() => toggleMobileDropdown()}
        >
          X
        </button>
          <button onClick={() => handleFetchCategory('beautyandpersonalcare')}> Beauty and Personal Care
            </button>
          <button onClick={() => handleFetchCategory('clothingandfashion')}> Clothing and Fashion</button>
          <button onClick={() => handleFetchCategory('healthandwellness')}> Health and Wellness</button>
          <button onClick={() => handleFetchCategory('sportsandoutdoors')}> Sports and Outdoors</button>
          <button onClick={() => handleFetchCategory('homeandfurnitures')}> Home and Furnitures</button>
          <button onClick={() => handleFetchCategory('foodandbeverages')}> Food and Beverages</button>
          <button onClick={() => handleFetchCategory('booksandmedia')}> Books and Media</button>
          <button onClick={() => handleFetchCategory('toysandgames')}> Toys and Games</button>
          <button onClick={() => handleFetchCategory('electronics')}> Electronics</button>
    </div>
    </div>
  
  );
};

export default NavBar;
