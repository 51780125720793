import imageUrlBuilder from '@sanity/image-url';
import {createClient} from '@sanity/client';


export const client =createClient({
    projectId:'wec4d0yc',
    dataset:'production',
    useCDN: true,
    apiVersion: '2023-05-03',
    
});
const builder = imageUrlBuilder(client);
export const urlFor = (source) => builder.image(source);
