import React from 'react';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { urlFor } from '../sanity/sanityClient';
import { useStateContext } from '../context/StateContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FetchCategory = ({ subCategories }) => {
  const { decQty, incQty, qty, onAdd } = useStateContext();

  const handleAddToCart = (product) => {
    onAdd(product, qty);
    toast.success(`${qty} ${product.name} added to the cart.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000, // milliseconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const shouldShowComponent = window.innerWidth > 768;

  return (
    <div className='itemListTwo'>
      <ToastContainer />
      {subCategories &&
        subCategories.map((product) => (
          <div key={product._id} className="imgGape">
            <div className="small-images-container">
            {product.soldout? <span style={{position:'absolute', top:5, left:0, backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'red', padding:'5px', borderRadius:'5px'}}>{product.soldout}</span>:''}
                  {product.discount? <span style={{position:'absolute', top:5, left:230,whiteSpace:'nowrap', backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'white', padding:'5px', borderRadius:'5px'}}>{product.discount}</span>:''}
              {product.image.map((image, i) => (
                <span key={i} style={{ cursor: 'pointer', margin: '0 5px' }}>
                  <img
                    className="small-image-two"
                    src={urlFor(image)}
                    alt={product.name}
                  />
                </span>
              ))}
            </div>
             <div className="result-display">
            <p>Tzs:{product.price.toLocaleString()}/=</p>
            <p>{product.details}</p>
            </div>
            <span style={{ display: 'block', margin: '10px 0' }}>
              <div style={{ display: 'flex' }}>
                {shouldShowComponent ? (
                  <div className="reviews">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiOutlineStar />
                    <p>(4)</p>
                    <span className="quantity" style={{ display: 'flex', alignItems: 'center' }}>
                      <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ cursor: 'pointer' }} onClick={decQty}>
                          <AiOutlineMinus />
                        </span>
                        <span style={{ margin: '0 10px' }}>{qty}</span>
                        <span style={{ cursor: 'pointer' }} onClick={incQty}>
                          <AiOutlinePlus />
                        </span>
                        <span style={{ cursor: 'pointer', backgroundColor: '#f02d34' }}>
                          <button
                            type="button"
                            className="add-to-cart"
                            onClick={() => handleAddToCart(product)}
                          >
                            Add to Cart
                          </button>
                        </span>
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="reviews">
                    <span>
                      <button
                        type="button"
                        className="add-to-cart"
                        onClick={() => handleAddToCart(product)}
                        style={{ cursor: 'pointer', backgroundColor: 'red', borderRadius: '10px', marginLeft: '100px' }}
                      >
                        Add to Cart
                      </button>
                    </span>
                  </div>
                )}
              </div>
            </span>
          </div>
        ))}
    </div>
  );
};

export default FetchCategory;
