import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { useStateContext } from '../context/StateContext';
import { urlFor } from '../sanity/sanityClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductDetail = ({ products }) => {
  const [index, setIndex] = useState(0);
  const { decQty, incQty, qty, onAdd } = useStateContext();

  const handleAddToCart = (product) => {
    onAdd(product, qty, index);
    toast.success(`${qty} ${product.name} added to the cart.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <div className='itemList'>
      <ToastContainer />
      {products.map((product) => (
        <div key={product._id} style={{ width: '250px', display: 'flex', flexDirection: 'column', alignItems: 'center', transition: 'transform 0.3s' }}>
          <h3>{product.name}</h3>
          <div className="image-container">
            <img
              src={urlFor(product.image && product.image[index])}
              alt={product.name}
              className="product-detail-image"
            />
          </div>
          <div className="small-images-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '40px' }}>
            {product.soldout? <span style={{position:'absolute', top:5, left:0, backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'red', padding:'5px', borderRadius:'5px'}}>{product.soldout}</span>:''}
                  {product.discount? <span style={{position:'absolute', top:5, left:100,whiteSpace:'nowrap', backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'white', padding:'5px', borderRadius:'5px'}}>{product.discount}</span>:''}
            {product.image.map((image, i) => (
              <span
                key={i}
                style={{ cursor: 'pointer', margin: '0 5px' }}
                className={i === index ? 'small-image selected-image' : 'small-image'}
                onMouseEnter={() => setIndex(i)}
              >
                <img
                  src={urlFor(image)}
                  width={60}
                  height={70}
                  alt={product.name}
                />
              </span>
            ))}
          </div>
          <span style={{ display: 'block', margin: '10px 0' }}>
            <p style={{ color: 'red' }}>TZS:<strong>{product.price.toLocaleString()}/=</strong></p>
            <p>{product.details}</p>
            <div style={{ display: 'flex' }}>
              <div className="reviews">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiOutlineStar />
                <p>(4)</p>
                <span className="quantity" style={{ display: 'flex', alignItems: 'center' }} >
                  <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ cursor: 'pointer' }} onClick={decQty}><AiOutlineMinus /></span>
                    <span style={{ margin: '0 10px' }}>{qty}</span>
                    <span style={{ cursor: 'pointer' }} onClick={incQty}><AiOutlinePlus /></span>
                  </span>
                </span>
              </div>
            </div>
            <div className="buttons">
              <button type="button" className="add-to-cart" onClick={() => handleAddToCart(product)}>
                Add to Cart
              </button>
            </div>
          </span>
        </div>
      ))}
    </div>
  );
};

export default ProductDetail;
