import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GoSearch } from 'react-icons/go';
import SearchContact from './SearchContact';

export default function Contacts({ contacts, setListSet, changeChat, showCntcts }) {
  const [currentUserName, setCurrentUserName] = useState(undefined);
  const [currentUserImage, setCurrentUserImage] = useState(undefined);
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await JSON.parse(
        localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
      );

      if (data) {
        setCurrentUserName(data.username);
        setCurrentUserImage(data.avatarImage);
      }
    }

    fetchData();
  }, []);


  useEffect(() => {
    if (searchQuery.length > 0) {
      const filteredResults = contacts.filter((contact) =>
        contact.username.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setSearching(true);
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
      setSearching(false);
    }

  }, [contacts, searchQuery]);

  const changeCurrentChat = (index, contact) => {
    setCurrentSelected(index);
    changeChat(contact);
    setSearching(false);
    setListSet(true);
    showCntcts();
  };

  const sortedContacts = [...contacts].sort((a, b) => a.username.localeCompare(b.username));

  return (
    <>
      {currentUserImage && currentUserImage && (
        <Container>
          <div className="current-user">
            <div className="avatar" style={{display:'flex',position: 'absolute', top:'0px', right:'0'}}>
              <img
                src={`data:image/svg+xml;base64,${currentUserImage}`}
                alt="avatar"
                hidden
              />
              <div className="username">
              <h2 hidden>{currentUserName}</h2>
              </div>
            </div><br/><br/><br/>
            
              <div className="search-form"
                style={{
                  display: 'flex',
                  width: '90%',
                  marginLeft: '1%',
                  backgroundColor: '#f0f0f0',
                  border: 'solid',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',

                }}>
                <div><GoSearch /></div>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  required
                  placeholder="Bae/User"
                  style={{ width: '60%', height: '20px', borderRadius: '5px', border: 'none', outline: 'none' }}
                />
            </div>
            <div>____</div>
          </div>
          <div><br/><br/>
          {searching ?
            (<div className="contacts">
              <SearchContact searchResults={searchResults} currentSelected={currentSelected} changeCurrentChat={changeCurrentChat} />
            </div>) : (
              <div className="contacts">
                {sortedContacts.length > 0 &&
                  sortedContacts.map((contact, index) => {
                    return (
                      <div
                        key={contact._id}
                        className={`contact ${
                          index === currentSelected ? "selected" : ""
                        }`}
                        onClick={() => changeCurrentChat(index, contact)}
                      >
                        <div className="avatar">
                          <img
                            src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                            alt=""
                          />
                          <b>
                            <p style={{ margin: "0" }}>{contact.username}</p>
                          </b>
                        </div>
                      </div>
                    );
                  })}
              </div>)}
            </div>

        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 10% 75% 15%;
  height:100vh;
  overflow: auto;
  background-color: #f0f0f0;
  .brand {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    img {
      height: 2rem;
    }
    h3 {
      color: white;
    }
  }
  .contacts {
    display: flex;
    flex-direction: column;
    height:60vh;
    align-items: center;
    overflow: auto;
    gap: 0;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #f0f0f0;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      background-color: #ffffff34;
      min-height: 5rem;
      cursor: pointer;
      width: 90%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      flex-direction:column;
      gap: 0;
      align-items: center;
      transition: 0.5s ease-in-out;
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: black;
        }
      }
    }
    
  }

  .current-user {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .avatar {
      img {
        height: 4rem;
        max-inline-size: 100%;
      }
    }
    .username {
      h2 {
        color: black;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }
`;

