import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import SetAvatar from "./components/SetAvatar";
//import Controller from "./components/Controller";
import Chat from "./pages/Chat";
import Supportbrand from "./components/Supportbrand";
import Products from './components/Products';
import Login from "./pages/Login";
import Register from "./pages/Register";
import RequestReset from "./pages/RequestReset";
import PasswordReset from "./pages/PasswordReset";
import CategoryItems from './components/CategoryItems';
import { StateContext } from './context/StateContext';
export default function App() {
  return (
    <HashRouter>
    <StateContext>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/setavatar" element={<SetAvatar />} />
        <Route path="/request" element={<RequestReset />} />
        <Route path="/resetin/:token" element={<PasswordReset />} />
        <Route path="/" element={<Chat />} />
        <Route path="/category/:slug" element={<Products />} />
        <Route path="/social" element={<Supportbrand />}/>
        <Route path="/items/:categoryName" element={<CategoryItems />}/>
      </Routes>
       </StateContext>
    </HashRouter>
  );
}


if ('serviceWorker' in navigator ){
  window.addEventListener ('load', ()=>{
    navigator.serviceWorker.register('/service-worker.js')
  })
}