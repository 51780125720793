import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { urlFor } from '../sanity/sanityClient';
import PuzzleMessage from './PuzzleMessage';
import { sendsurpriseroute, receivesurpriseroute } from "../utils/APIRoutes";

const ChatWindow = ({ user, socket, currentChat, selectedProduct, productDeselect }) => {
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [message, setMessage] = useState('');
  const [options, setOptions] = useState(['1.', '2.', '3.', '4.', '5.']);
  const [submittedOption, setSubmittedOption] = useState('');
  const [sentOption, setSentOption] = useState('');
  const [replied, setReplied] = useState(false);
  const [choice, setChoice] = useState('');
  const lastMessageRef = useRef(null);
  const secondToLastMessageRef = useRef(null);


  useEffect(() => {
    async function fetchSurprises() {
       const data = await JSON.parse(
         localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
       );
      const response = await axios.post(receivesurpriseroute, {
        from: data._id,
        to: currentChat._id,
      });
      if(response){
        setMessages(response.data);
      }
    }

    if (currentChat) {
      fetchSurprises();
    }
  }, [currentChat, socket]);

  useEffect(() => {
    const getCurrentChat = async () => {
      if (currentChat) {
        await JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
        )._id;
      }
    };
    getCurrentChat();
  }, [currentChat]);


  const puzzle={message: message, options:options}
  const messageSet=(message)=>{
    setMessage(message)
  }

  const optionsSet=(options)=>{
    setOptions(options)
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    
    const data = await JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY)
    );

    const trimmedMessage = message.trim();
    const trimmedOptions = options.map((option) => option.trim());
    //console.log(trimmedOptions);

    const hasFilledOption = trimmedOptions.some((option) => option !== '');

    if (trimmedMessage !== '' && hasFilledOption) { 
       socket.current.emit('message', {
         message: trimmedMessage,
         options: trimmedOptions,
         from: socket.current.id,
         to: currentChat,
       });
      
      await axios.post(sendsurpriseroute, {
      from: data._id,
      to: currentChat,
      message: trimmedMessage,
      options: trimmedOptions
    });
       
    const msgs = [...messages];
     msgs.push({ fromSelf: true, message: trimmedMessage, options:trimmedOptions,
      from: socket.current.id, to:currentChat });
     setMessages(msgs);

      sentMessage();
      setMessage('');
      setOptions(['1.', '2.', '3.', '4.', '5.']);
    }
  };

  const handleOptionPointer = (index) => {
    if (selectedProduct) {
      const updatedOptions = [...options];
      updatedOptions[index] = `${index + 1}. ${selectedProduct}`;
      setOptions(updatedOptions);
    }
  }; 

  useEffect(() => {
    // Capture the current value of socket.current in a variable
    const currentSocket = socket.current;

    // Add an event listener using the captured socket
    currentSocket.on('messageResponse', (data) => {
      
      setArrivalMessage({fromSelf: false, message:data.message, 
        options: data.options});
        setShowButton(true);
        setSentOption('');
      setChoice('');
    });

    // Cleanup function with the captured socket
    return () => {
      currentSocket.off('msg-recieve');
    };
  }, [socket]);

   useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    // Capture the current value of socket.current in a variable
    const currentSocket = socket.current;

    // Add an event listener using the captured socket
    currentSocket.on('selectedOption', (data) => {
      setReplied(true);
      setChoice(data);
      setSentOption(data);
      setShowButton(false);
    });

    // Cleanup function with the captured socket
    return () => {
      currentSocket.off('selectedOption');
    };
  }, [socket]);

  useEffect(() => {
    if (lastMessageRef.current && secondToLastMessageRef.current) {
      secondToLastMessageRef.current.scrollIntoView({ behavior: 'smooth' });

      setTimeout(() => {
        lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [messages, choice]);

  const handleInputChange = (e) => {
    let value = e.target.value;
    const numericValue = value.replace(/\D/g, '');
    const validValues = ['1', '2', '3', '4', '5'];
    const limitedValue = validValues.includes(numericValue) ? numericValue : '';
    setSubmittedOption(limitedValue);
  };

  const sentMessage = () => {
    setChoice('');
    productDeselect();
  };

  const handleTyping = () => socket.current.emit('typing', `${user} is typing`);

  const confirmOption = (e) => {
    e.preventDefault();
    const currentSocket = socket.current;
    const trimmedCorrectOption = submittedOption.trim();

    if (trimmedCorrectOption !== '') {
      socket.current.emit('option', {
        option: submittedOption,
        to: currentChat,
        from: currentSocket.id,
      });
      setSentOption(submittedOption);
      setChoice(submittedOption);
      setSubmittedOption('');
      setShowButton(false);
    }
  };

  return (
    <div className="chat-window">
      <div className="message-container">
        {messages.map((message, index) => (
          <div
            key={index}
            ref={
              index === messages.length - 2
                ? secondToLastMessageRef
                : index === messages.length - 1
                ? lastMessageRef
                : null
            }
            message={message}
            className={`message ${message.fromSelf?"msg-sender":"msg-receiver"}`}
          >
            {message && message.from === socket.current.id? (
              <div className="msg-to">
                <span>
                  {message.message}
                  <br />
                  {sentOption && (
                    <div key={index} className="img-option">
                      {message.options && message.options.sort().map((option, index) => (
                        <div key={index}>
                          {option.includes('image-') ? (
                            
                              <img
                                className="option"
                                src={urlFor(option.split('. ')[1])}
                                alt={option}
                              />
                          
                          ) : (
                            <p>{option}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </span>
              </div>
            ) : (
              <div className="msg-fro">
                <span>
                  {message.message}
                  <br />
                  {sentOption && (
                    <div key={index} className="img-option">
                      {message.options && message.options.sort().map((option, index) => (
                        <div key={index}>
                          {option.includes('image-') ? (
                            
                              <img
                                className="option"
                                src={urlFor(option.split('. ')[1])}
                                alt={option}
                              />
                            
                          ) : (
                            <p>{option}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </span>
              </div>
            )}
          </div>
        ))}
        {replied && choice && <p className="a-choice">{choice}</p>}
      </div>
      {submittedOption > 0 && <p>Your Selected Option: {submittedOption}</p>}
      {showButton ? (
        <form className="puzzle-message-option" onSubmit={confirmOption}>
          <input
            type="number"
            value={submittedOption}
            onChange={handleInputChange}
            placeholder="Select Option from 1 to 5"
            required
          />
          <button type="submit">Send</button>
        </form>
      ) : (
        <div className="puzzle-message-container">
          <PuzzleMessage
            currentChat={currentChat}
            puzzle={puzzle}
            messageSet={messageSet}
            optionsSet={optionsSet}
            selectedProduct={selectedProduct}
            handleSubmit={handleSubmit}
            handleOptionPointer={handleOptionPointer}
            sentMessage={sentMessage}
            handleTyping={handleTyping}
          />
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
