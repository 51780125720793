import React from 'react';
import { urlFor } from '../sanity/sanityClient';

const SearchProduct = ({ searchResults, handleProductClick}) => {
  

  return (

      <div className="search-results-two" >
          {searchResults.length>0? (searchResults.map((result) => (
            <div key={result._id} className="result-item">
              
                <div className="category-content">
                  {result.image[0] && (
                    <img
                      src={urlFor(result.image[0])}
                      width={250}
                      height={250}
                      alt={result.name}
                      style={{ borderRadius: '10px',  background: 'linear-gradient(to right, #ffecd2, #fcb69f)' }}
                      onClick={() => handleProductClick(result)}
                    />
                  )}
                  <div className="result-display">
                  <p style={{fontSize:'small', color:'red'}}>Tzs:<strong>{result.price.toLocaleString()}/=</strong></p>
                  <p>{result.name}</p>
                  </div>
                </div>
             
            </div>
            )
        )):(<p>No results found!</p>)}
      </div>
   
  );
};

export default SearchProduct;
