import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { AiOutlineMinus, AiOutlinePlus, AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { GoHome} from 'react-icons/go';
import { client, urlFor } from '../sanity/sanityClient';
import { useStateContext } from '../context/StateContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoChevronRight, GoChevronLeft } from 'react-icons/go';
import Cart from './Cart';
import { AiOutlineShopping } from 'react-icons/ai';
import Loader from './Loader';

const CategoryItems = () => {
  const { decQty, incQty, qty, onAdd } = useStateContext();
  const { categoryName } = useParams();
  const { totalQuantities, showCart, setShowCart } = useStateContext();
  const [subCategories, setSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndices, setCurrentIndices] = useState([]);


  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        setIsLoading(true);
        const data = await client.fetch(`*[_type == '${categoryName}']`);
        setSubCategories(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, [categoryName]);

  const handleAddToCart = (product) => {
    onAdd(product, qty);
    toast.success(`${qty} ${product.name} added to the cart.`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const shouldShowComponent = window.innerWidth > 768;

  useEffect(() => {
    // Initialize the currentIndices array with zeros for each product
    setCurrentIndices(Array(subCategories.length).fill(0));
  }, [subCategories]);

  const handleSlide = (index, direction) => {
    setCurrentIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      const productImagesLength = subCategories[index].image.length;
      newIndices[index] = Math.max(0, Math.min(newIndices[index] + direction, productImagesLength - 1));
      return newIndices;
    });
};


  return (
    <>
      <div style={{ backgroundColor: '#00000076', padding: '15px', borderRadius: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <Link to={'/'} style={{color:'white', textDecoration: 'none'}}><GoHome/>Home</Link>
        <button
          type="button"
          className="cart-icon"
          onClick={() => setShowCart(true)}
          style={{ right: '0' }}
        >
          <AiOutlineShopping />
          <span className="cart-item-qty">{totalQuantities}</span>
        </button>
        <div className={`modal ${showCart ? 'active' : ''}`}>
          <Cart />
        </div>
      </div>
      <div className='itemListTwo'>
        <ToastContainer />
        {isLoading ? (
          <Loader />
        ) : subCategories.length > 0 ? (
          subCategories.map((product, index) => (
                <div key={product._id} className="imgGape">
                  <div className="small-images-container">
                {product.soldout? <span style={{position:'absolute', top:5, left:0, backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'red', padding:'5px', borderRadius:'5px'}}>{product.soldout}</span>:''}
                  {product.discount? <span style={{position:'absolute', top:5, left:230,whiteSpace:'nowrap', backgroundColor:'rgba(0,0,0,0.5)', 
                  color:'white', padding:'5px', borderRadius:'5px'}}>{product.discount}</span>:''}
                    {product.image.map((image, i) => (
                      <span
                        key={i}
                        className={i === currentIndices[index] ? '' : 'hide'}
                      >
                        <img
                          className="small-image-two"
                          src={urlFor(image)}
                          alt={product.name}
                        />
                      </span>
                    ))}
                  </div>
                  <div style={{display:'flex', justifyContent:'space-between'}}>
                   <GoChevronLeft className='left-arrow'style={{color:'red'}} onClick={() => handleSlide(index, -1)} />
                  <GoChevronRight className='right-arrow' style={{color:'red'}} onClick={() => handleSlide(index, 1)} />
                   </div>
              <div className="result-display">
                <p style={{color:'red'}}>Tzs:{product.price.toLocaleString()}/=</p>
                <p>{product.details}</p>
              </div>
              <span style={{ display: 'block', margin: '10px 0' }}>
                <div style={{ display: 'flex' }}>
                  {shouldShowComponent ? (
                    <div className="reviews">
                    <div style={{display:'flex', flexDirection:'column'}}>
                      <AiOutlineStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      <AiFillStar />
                      </div>
                      <p>(4)</p>
                      <span className="quantity" style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="quantity-desc" style={{ display: 'flex', alignItems: 'center', borderRadius:'10px' }}>
                          <span style={{ cursor: 'pointer' }} onClick={decQty}>
                            <AiOutlineMinus />
                          </span>
                          <span style={{ margin: '0 10px' }}>{qty}</span>
                          <span style={{ cursor: 'pointer' }} onClick={incQty}>
                            <AiOutlinePlus />
                          </span>
                          <span style={{ cursor: 'pointer', backgroundColor: '#f02d34', borderRadius:'10px' }}>
                            <button
                              type="button"
                              className="add-to-cart"
                              onClick={() => handleAddToCart(product)}
                            >
                              Add to Cart
                            </button>
                          </span>
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className="reviews">
                      <span>
                        <button
                          type="button"
                          className="add-to-cart"
                          onClick={() => handleAddToCart(product)}
                          style={{ cursor: 'pointer', backgroundColor: 'red', borderRadius: '10px', marginLeft: '100px' }}
                        >
                          Add to Cart
                        </button>
                      </span>
                    </div>
                  )}
                </div>
              </span>
            </div>
          ))
        ) : (
          <p style={{ textAlign: 'center' }}><strong>No products in this category yet</strong></p>
        )}
      </div>
    </>
  );
};
export default CategoryItems;
