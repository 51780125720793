import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { GoArrowLeft } from 'react-icons/go';
import { MdDeleteForever } from 'react-icons/md';
import styled from "styled-components";
import ChatInput from "./ChatInput";
import Logout from "./Logout";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import messageSound from "../assets/sounds/message.mp3";
import { urlFor } from '../sanity/sanityClient';
import { sendmessageroute, recievemessageroute, deletemessageroute } from "../utils/APIRoutes";
import SurpriseModal from "./SurpriseModal";
import "../components/App.css";

export default function ChatContainer({ currentChat, socket, selectedProduct, productDeselect, handleBack, categories, fetchMessaged }) {
  const [messages, setMessages] = useState([]);
  const scrollRef = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [backMessage, setBackMessage] = useState('');

  useEffect(() => {
    async function fetchMessages() {
      const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
      const response = await axios.post(recievemessageroute, {
        from: data._id,
        to: currentChat._id,
      });
      setMessages(response.data);
    }

    if (currentChat) {
      fetchMessages();
    }
  }, [currentChat]);

  useEffect(() => {
    const getCurrentChat = async () => {
      if (currentChat) {
        await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY))._id;
      }
    };
    getCurrentChat();
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
    socket.current.emit("send-msg", {
      to: currentChat._id,
      from: data._id,
      msg,
      sender:data.username,
      receiver:currentChat.email
    });

    await axios.post(sendmessageroute, {
      from: data._id,
      to: currentChat._id,
      message: msg,
    });

    const msgs = [...messages];
        const stamp = new Date().toLocaleString('default', {
          hour:'numeric',
          minute:'numeric',
        });

    msgs.push({ fromSelf: true, message: msg, stamp });
    setMessages(msgs);
    fetchMessaged();
  };

  const handleDeleteMSG = async () => {
    const data = await JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCALHOST_KEY));
    try {
      const response = await axios.post(deletemessageroute, {
        from: data._id,
        to: currentChat._id
      });
      setBackMessage(response.data.msg);
      setMessages([]);
      fetchMessaged();
    } catch (error) {
      console.error('Error deleting messages:', error);
    }
  };

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieve", (msg) => {
        const stamp = new Date().toLocaleString('default', {
          hour:'numeric',
          minute:'numeric',
        });
        setArrivalMessage({ fromSelf: false, message: msg, stamp });
        fetchMessaged();
        if (!document.hasFocus() || document.hasFocus()) {
          const sound = new Audio(messageSound);
          sound.play();
        }
      });
    }
  }, [socket, currentChat.username, fetchMessaged]);

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <Container>
      <div className="chat-header">
        <div className="user-details">
          <div className="avatar">
            <img
              src={`data:image/svg+xml;base64,${currentChat.avatarImage}`}
              alt="uservatar"
            />
          </div>
          <div className="username">
            <h3>{currentChat.username}</h3>
          </div>
        </div>
        <Logout />
      </div>
      <br />
      {messages.length < 1 && backMessage && (
        <div>
          <p>{backMessage}</p>
        </div>
      )}
      <div className="chat-messages">
        {messages.length > 0 && messages.map((message) => {
         const timestamp = message.timestamp || '';
         
          if (message.message.includes('image-')) {
            const matchedCategory = categories.find(category => category.image[0].asset._ref === message.message);
            return (
              <div ref={scrollRef} key={uuidv4()}>
                <div
                  className={`message ${message.fromSelf ? "sended" : "recieved"}`}
                >
                  <div className="content">
                    {matchedCategory && (
                      <Link to={`/category/${matchedCategory.slug.current}`}>
                        <img
                          className="option"
                          src={urlFor(message.message)}
                          alt="messed up!"
                          width={100}
                        />
                      </Link>
                    )}
                    <span className="timestamp">
                    {timestamp? new Date(timestamp).toLocaleTimeString('default', { hour:'numeric', minute:'numeric',}):message.stamp}</span>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div ref={scrollRef} key={uuidv4()}>
                <div
                  className={`message ${message.fromSelf ? "sended" : "recieved"}`}
                >
                  <div className="content">
                    <p style={{margin:'0'}}>{message.message}</p>
                    <span className="timestamp">
                    {timestamp? new Date(timestamp).toLocaleString('default', { hour:'numeric', minute:'numeric',}):message.stamp}</span>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
      <div>
        <SurpriseModal
          isOpen={isModalOpen}
          socket={socket}
          currentChat={currentChat._id}
          user={currentChat.username}
          onRequestClose={() => setIsModalOpen(false)}
          selectedProduct={selectedProduct}
          productDeselect={productDeselect}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 style={{marginLeft:'20px'}} onClick={handleBack}><GoArrowLeft /></h2>
        <button
          style={{
            width: '30px',
            height: '30',
            padding: '0.3rem 2rem',
            borderRadius: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#ffffff34',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => setIsModalOpen(true)}
        >
          🎁 Gift!
        </button>
        <button
          style={{
            position: 'relative',
            marginRight: '30px',
            borderRadius: '0.5rem',
            color: 'black',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={handleDeleteMSG}
        >
          <MdDeleteForever />
        </button>
      </div>
      <ChatInput handleSendMsg={handleSendMsg} selectedProduct={selectedProduct} productDeselect={productDeselect} />
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-column: 10% 40% 50%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 15% 50% 35%;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    .user-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: black;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #f4e3c1;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 100%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: black;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 90%;
        }
      }
      
      .timestamp {
        font-size: 0.8rem;
        margin-left:0.1rem;
        color: #777;
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: rgb(194, 243, 194);
      }

    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #f5ccc2;
      }
    }
  }
`;
