import React from 'react';


const PuzzleMessage = ({ currentChat, selectedProduct, 
  handleTyping, sentMessage,puzzle, messageSet, optionsSet, 
  handleSubmit, handleOptionPointer}) => {
  

  return (
    <div className="puzzle-message-container">
      <form className="puzzle-message-form" onSubmit={handleSubmit}>
        <input
          type="text"
          value={puzzle.message}
          onChange={(e) => messageSet(e.target.value)}
          onKeyDown={handleTyping}
          placeholder="Your surprise message.📎"
          required
        />
        {puzzle.options.map((option, index) => (
          <input
            key={index}
            type="text"
            value={option}
            onChange={(e) =>
              optionsSet([...puzzle.options.slice(0, index), e.target.value, ...puzzle.options.slice(index + 1)])
            }
            onPointerDown={() => handleOptionPointer(index)}
            placeholder={`Option ${index + 1} Type/Drop link`}
            onKeyDown={handleTyping}
            required={index === 1}
          />
        ))} <br />
        <button type="submit">Surprise!</button>
      </form>
    </div>
  );
};

export default PuzzleMessage;
