import React from 'react';


const SearchContact = ({ searchResults, changeCurrentChat, currentSelected}) => {
  

  return (

      <div className="contacts" >
          {searchResults.length>0? (searchResults.map((contact, index) => {
            return (
              <div
                key={contact._id}
                className={`contact ${
                  index === currentSelected ? "selected" : ""
                }`}
                onClick={() => changeCurrentChat(index, contact)}
              >
                <div className="avatar">
                  <img
                    src={`data:image/svg+xml;base64,${contact.avatarImage}`}
                    alt=""
                  />
                  <b>
                    <p style={{ margin: "0" }}>{contact.username}</p>
                  </b>
                </div>
              </div>
            );
            }
        )):(<p>No results found!</p>)}
      </div>
   
  );
};

export default SearchContact;
