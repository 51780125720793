//App.jsx
import React from 'react';
import brand from './brand.jpg';
import friends1 from './friends1.jpeg';
import friends2 from './friends2.jpeg';


const Supportbrand=()=>{
    

return(
   <div className='friendssupport'>
     <div className='brand-logo'>
     <img 
                 src={brand} 
                 width={35}
                 height={35}
                 alt='brand-logo'
                 />
    <h3>Support and Brand</h3>
     </div>
     <div className='branding-ads'>
     <p>To support business growth, friends play a great role as immediate consummers of products.</p>
      <img 
                 src={friends1} 
                 width={250}
                 height={200}
                 alt='brand-logo'
                 />
         <p>This part is allocated for posting images/video clips of friends who have dedicated their time to 
         show loyality by supporting and branding businesses of their friends. As they receive shipped items, or buy products physically from
         stores/shops pictures can be taken during the event, tag the brand acount and shared here.</p>
         <img 
                 src={friends2} 
                 width={250}
                 height={200}
                 alt='brand-logo'
                 />
    <p>It is also one of the special column where key opion leaders (KOL) with high social following can post 
        products they have shopped, and not only support popularity and growth of BridgenApp but also boost 
        visibility of the products they buy.</p>
        <p>Lastly, it is expected to be used to showcase business of salers/merchants with premium registration </p>
    </div>
   </div>
);
}


export default Supportbrand;
