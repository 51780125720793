import React from 'react';


const Loader = () => {
  return (
    <div className="loader">
      <div className="circle red"></div>
      <div className="circle blue"></div>
      <div className="circle green"></div>
    </div>
  );
}

export default Loader;
