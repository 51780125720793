import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import ChatWindow from './ChatWindow';

Modal.setAppElement("#root"); 

export default function SurpriseModal({ isOpen, onRequestClose, currentChat, socket, user, selectedProduct, productDeselect}) {
  const isMobile = window.innerWidth < 768;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="modal-overlay" 
      contentLabel="Surprise Modal"
      style={{
    content: {
      width: isMobile?'42%':'45%',
      height: isMobile?'65vh':'53.56vh',
      left: isMobile?'0':'10px',
      top:'150px'
    }
  }}
    >
      <CloseButton onClick={onRequestClose}>X</CloseButton>
      <ModalContent>
        <h2>Challenge your loved one!</h2>
        <p>Live gift challenge. Type gifts or click and drop items to make <b style={{color:'blue'}}>{user}</b> guess one.</p>
        <ChatWindow socket={socket} currentChat={currentChat} selectedProduct={selectedProduct} productDeselect={productDeselect} />
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  /* Add styles for your modal content here */
  background-color: white;
  padding: 2px;
  border-radius:5px;
`;

const CloseButton = styled.button`
  /* Add styles for the close button here */
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the right position as needed */
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
`;

// Add CSS for the modal overlay here
const modalOverlayStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
  zIndex: 1000, // Ensure it's above other content
  display:"flex",
  justifyContent:"center",
  alignItems: "center", 
};

// Use the modalOverlayStyle as a class for the modal overlay
Modal.defaultStyles.overlay = modalOverlayStyle;
