import React, { useRef } from 'react';
import { AiOutlineMinus, AiOutlineShopping, AiOutlinePlus, AiOutlineLeft } from 'react-icons/ai';
import { TiDeleteOutline } from 'react-icons/ti';


import { useStateContext } from '../context/StateContext';
import { urlFor } from '../sanity/sanityClient';


const Cart = () => {
  const cartRef = useRef();
  const {
    totalPrice,
    totalQuantities,
    cartItems,
    setShowCart,
    toggleCartItemQuantity,
    onRemove,
  } = useStateContext();

  const handleCheckout = async () => {
    alert("This feature not enabled yet, chat with Admin!");

  };

  return (
    <div className="cart-modal-overlay" ref={cartRef}
    >
      <div className="cart-modal-content" >
        <button
          type="button"
          className="cart-heading"
          onClick={() => setShowCart(false)}
        >
          <AiOutlineLeft />
          <span className="heading">Your Cart</span>
          <span className="cart-num-items">({totalQuantities} items)</span>
        </button>
        
        {cartItems.length < 1 && (
          <div className="empty-cart">
            <AiOutlineShopping size={150} />
            <h3>Your shopping bag is empty</h3>
            <button type="button" className="btn" onClick={() => setShowCart(false)}>
              Continue Shopping
            </button>
          </div>
        )}

        <div className="product-container">
          {cartItems.length >= 1 &&
            cartItems.map((item) => (
              <div className="product" key={item._id}>
                <img
                  src={urlFor(item?.image[0])}
                  className="cart-product-image"
                  alt="productname"
                />
                <div className="item-desc">
                  <div className="flex top">
                    <h5>{item.name}: TZS{item.price.toLocaleString()}/=</h5>
                    
                  </div>
                  <div className="flex bottom">
                    <div>
                      <p className="quantity-desc" style={{backgroundColor:'white'}}>
                        <span className="minus" onClick={() => toggleCartItemQuantity(item._id, 'dec')}>
                          <AiOutlineMinus />
                        </span>
                        <span className="num">{item.quantity}</span>
                        <span className="plus" onClick={() => toggleCartItemQuantity(item._id, 'inc')}>
                          <AiOutlinePlus />
                        </span>
                        <button
                      type="button"
                      className="remove-item"
                      onClick={() => onRemove(item)}
                    >
                      <TiDeleteOutline />
                    </button>
                      </p>

                    </div>
                    
                  </div>
                </div>
              </div>
            ))}
        </div>
        {cartItems.length >= 1 && (
          <div className="cart-button" style={{textAlign:'center'}}>
        
              <h3>SubTotal:TZS{totalPrice.toLocaleString()}/=</h3>

            <div className="btn-container">
              <button type="button" className="btn" onClick={handleCheckout}>
                Pay Now
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
