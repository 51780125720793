
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyCYWAslP6saTETxpIeorz0S4MNwCOxodqU",
  authDomain: "brija-c77c8.firebaseapp.com",
  projectId: "brija-c77c8",
  storageBucket: "brija-c77c8.appspot.com",
  messagingSenderId: "194287624843",
  appId: "1:194287624843:web:25524894184d5d9462ea8e"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const getCurrentToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: "BCYYXt1yhekVq4dgop8qt3kl3634qUoQg2SJqEfr6vqOBIFOGdizbhvL-dCiGOLxD-RO9lVOhRy2s41W5FX8Rxc" });
    return currentToken;
  } catch (error) {
    console.error('Error getting current FCM token:', error);
    throw error;
  }
};


export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload);
  });
});


export const Sendrequest = () => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return getToken(messaging, { vapidKey: "BCYYXt1yhekVq4dgop8qt3kl3634qUoQg2SJqEfr6vqOBIFOGdizbhvL-dCiGOLxD-RO9lVOhRy2s41W5FX8Rxc" })
        .then((currentToken) => {
          if (currentToken) {
            console.log('Client Token: ', currentToken);
          } else {
            console.log('Failed to generate the registration token.');
          }
        })
        .catch((err) => {
          console.log('An error occurred when requesting to receive the token.', err);
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};
